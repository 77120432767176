/**
 * Validates a list of files based on various criteria.
 *
 * @param {FileList} files - The list of files to validate.
 * @param {number} maxFiles - Maximum number of files allowed.
 * @param {number} maxTotalSize - Maximum total size of all files in bytes.
 * @param {number} maxFileSize - Maximum size for an individual file in bytes.
 * @param {Array<string>} acceptedFormats - Array of accepted MIME types.
 * @param {number} minWidth - Minimum width for image files.
 * @param {number} minHeight - Minimum height for image files.
 * @param {Element} targetElement - The element to dispatch events from.
 * @returns {boolean} - False if validation fails, otherwise true.
 */
async function validateFiles(
  files,
  maxFiles,
  maxTotalSize,
  maxFileSize,
  acceptedFormats,
  minWidth,
  minHeight,
  targetElement
) {
  if (checkMaxFiles(files, maxFiles, targetElement)) return false;

  if (checkMaxTotalSize(files, maxTotalSize, targetElement)) return false;

  for (let file of files) {
    if (checkFileSize(file, maxFileSize, targetElement)) return false;
    if (checkAcceptedFormat(file, acceptedFormats, targetElement)) return false;
  }

  if (minWidth || minHeight) {
    if (await checkImageDimensions(files, minWidth, minHeight, targetElement)) return false;
  }

  return true;
}

/**
 * Checks if the number of files exceeds the maximum allowed.
 *
 * @param {FileList} files - The list of files to check.
 * @param {number} maxFiles - Maximum number of files allowed.
 * @param {Element} targetElement - The element to dispatch events from.
 * @returns {boolean} - True if the number of files exceeds the limit, otherwise false.
 */
function checkMaxFiles(files, maxFiles, targetElement) {
  if (files.length > maxFiles) {
    sendError(`Cannot upload more than ${maxFiles} files.`, targetElement);
    return true;
  }
  return false;
}

/**
 * Checks if the total size of the files exceeds the maximum allowed.
 *
 * @param {FileList} files - The list of files to check.
 * @param {number} maxTotalSize - Maximum total size of all files in bytes.
 * @param {Element} targetElement - The element to dispatch events from.
 * @returns {boolean} - True if the total size exceeds the limit, otherwise false.
 */
function checkMaxTotalSize(files, maxTotalSize, targetElement) {
  const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
  if (maxTotalSize && totalSize > maxTotalSize) {
    sendError(
      `Total file size exceeds the max. limit of ${(maxTotalSize / 1024 / 1024).toFixed(0)} MB.`,
      targetElement
    );
    return true;
  }
  return false;
}

/**
 * Checks if the size of an individual file exceeds the maximum allowed.
 *
 * @param {File} file - The file to check.
 * @param {number} maxFileSize - Maximum size for an individual file in bytes.
 * @param {Element} targetElement - The element to dispatch events from.
 * @returns {boolean} - True if the file size exceeds the limit, otherwise false.
 */
function checkFileSize(file, maxFileSize, targetElement) {
  if (file.size > maxFileSize) {
    sendError(
      `File ${file.name} exceeds the max. file size of ${(maxFileSize / 1024 / 1024).toFixed(
        0
      )} MB.`,
      targetElement
    );
    return true;
  }
  return false;
}

/**
 * Checks if the file format is accepted.
 *
 * @param {File} file - The file to check.
 * @param {Array<string>} acceptedFormats - Array of accepted MIME types.
 * @param {Element} targetElement - The element to dispatch events from.
 * @returns {boolean} - True if the file format is accepted, otherwise false.
 */
function checkAcceptedFormat(file, acceptedFormats, targetElement) {
  if (acceptedFormats.length === 0) return false;
  if (acceptedFormats.includes(file.type)) return false;

  const fileTypeParts = file.type.split("/");
  for (const format of acceptedFormats) {
    const formatParts = format.split("/");
    if (formatParts[0] === fileTypeParts[0] && formatParts[1] === "*") return false;
  }

  sendError(`File ${file.name} is not an accepted format.`, targetElement);
  return true;
}

/**
 * Checks if the dimensions of image files meet the minimum requirements.
 *
 * @param {FileList} files - The list of files to check.
 * @param {number} minWidth - Minimum width for image files.
 * @param {number} minHeight - Minimum height for image files.
 * @param {Element} targetElement - The element to dispatch events from.
 * @returns {Promise<boolean>} - True if any image dimensions do not meet the requirements, otherwise false.
 */
function checkImageDimensions(files, minWidth, minHeight, targetElement) {
  if (files.length === 0) return false;

  const promises = Array.from(files).map(readImageDimensions);
  return Promise.all(promises)
    .then((results) => {
      for (const result of results) {
        if (result.width < minWidth || result.height < minHeight) {
          sendError(
            `File ${result.name} does not meet the minimum dimensions of ${minWidth}x${minHeight}.`,
            targetElement
          );
          return true;
        }
      }
      return false;
    })
    .catch((error) => {
      console.error("Error:", error);
      sendError(
        `An error occurred while validating the image dimensions: ${error.message}`,
        targetElement
      );
      return true;
    });
}

/**
 * Reads the dimensions of an image file.
 *
 * @param {File} file - The image file to read.
 * @returns {Promise<Object>} - An object containing the file name, width, and height.
 */
function readImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ name: file.name, width: img.width, height: img.height });
    img.onerror = () => reject(new Error(`Failed to load image: ${file.name}`));

    const reader = new FileReader();
    reader.onload = (event) => (img.src = event.target.result);
    reader.onerror = () => reject(new Error(`Failed to read file: ${file.name}`));

    reader.readAsDataURL(file);
  });
}

/**
 * Sends an error message as a custom event.
 *
 * @param {string} message - The error message to send.
 * @param {Element} targetElement - The element to dispatch events from.
 */
function sendError(message, targetElement) {
  targetElement.setCustomValidity(message);
  targetElement.dispatchEvent(new CustomEvent("fileUploadError", { detail: { message } }));
}

export { validateFiles, sendError };
